<template>
  <section class="md:mx-auto">
    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" :src="src"></iframe>
  </section>
</template>

<script>
export default {
  name: "SectionSoundclound",
  props: {
    src: {
      type: String,
      required: true,
    },
  },
};
</script>
