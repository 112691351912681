<template>
  <div class="wysiwyg-content">
    <slot>
      <div v-if="is_type_collapse">
        <accordion-basic :content="content_formatted" :to="to">
          <template #title>
            <h4
              class="mb-0 inline-flex items-center w-full transition duration-300 py-3"
              :class="toggle ? 'text-dark-blue' : ''"
              v-text="content.title"
            ></h4>
          </template>
        </accordion-basic>
      </div>
      <div v-else-if="is_type_video_embed">
        <section-video :src="content.src" class="mb-4"></section-video>
      </div>
      <div v-else-if="is_type_soundcloud">
        <section-soundcloud :src="content.src" class="mb-4"></section-soundcloud>
      </div>
      <div class="table-container mb-4" v-else-if="is_type_table">
        <div v-if="table_src">
          <img :src="table_src" style="max-width: 1024px" />
        </div>
      </div>
      <div v-else-if="is_type_image && image">
        <figure>
          <picture v-if="image" class="block overflow-hidden">
            <nuxt-img
              class="block picture rounded-t-lg overflow-hidden mx-auto"
              :src="image.url"
              fit="cover"
            ></nuxt-img>
          </picture>
          <figcaption v-if="content.caption" class="text-xs text-center mt-2">{{ content.caption }}</figcaption>
        </figure>
      </div>
      <div v-else v-html="content_formatted"></div>
    </slot>
  </div>
</template>

<script>
import AccordionBasic from "~/components/Accordion/AccordionBasic.vue";
import SectionVideo from "~/components/Sections/SectionVideo.vue";
import SectionSoundcloud from "~/components/Sections/SectionSoundcloud.vue";

export default {
  name: "SectionWysiwyg",
  setup() {
    const { locale } = useI18n();
    const router = useRouter();
    const config = useRuntimeConfig();

    return { locale: locale.value, router, config: config.public };
  },
  props: {
    pro: Object,
    content: {
      type: Object,
      required: false,
    },
    contentKey: {
      type: String,
      required: false,
      default: "text",
    },
    textarea: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: "",
    },
  },
  components: {
    AccordionBasic,
    SectionSoundcloud,
    SectionVideo,
  },
  data() {
    return {
      toggle: false,
      content_height: null,
    };
  },
  computed: {
    content_formatted() {
      if (this.content[this.contentKey]) {
        if (this.textarea) {
          return this.content[this.contentKey].replace(/\n/g, "<br />");
        } else {
          return this.content[this.contentKey];
        }
      }
      return "";
    },
    is_type_collapse() {
      return this.content.__typename === "bodyCopy_collapsableBlock_BlockType";
    },
    is_type_image() {
      return this.content.__typename === "bodyCopy_imageBlock_BlockType";
    },
    is_type_video_embed() {
      return this.content.__typename === "bodyCopy_blocVideoLink_BlockType";
    },
    is_type_soundcloud() {
      return this.content.__typename === "bodyCopy_soundcloudBlock_BlockType";
    },
    is_type_table() {
      return this.content.__typename === "bodyCopy_arrayBlock_BlockType";
    },
    table_src() {
      if (this.is_type_table) {
        if (this.locale === "fr") {
          return this.content && this.content.img.length > 0 ? this.content.img[0].url : false;
        } else {
          return this.content && this.content.imgEn.length > 0 ? this.content.imgEn[0].url : false;
        }
      }

      return false;
    },
    toggleHeight() {
      return this.toggle ? ` max-height: ${this.content_height}px` : "max-height: 0";
    },
    image() {
      if (this.is_type_image && this.content.image.length > 0) {
        return this.content.image[0];
      }

      return false;
    },
  },
  methods: {
    handleLinkClick(e) {
      const baseUrl = this.locale === "fr" ? this.config.frDomain : this.config.enDomain;
      const url = e.target.getAttribute("href");
      const target = e.target.getAttribute("target");
      if ((url.startsWith(baseUrl) || url.startsWith("/")) && target !== "_blank") {
        e.preventDefault();
        const to = url.replace(baseUrl, "");
        this.router.push(to);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.content) {
        this.content_height = this.$refs.content.clientHeight + 32;
      }

      // Hack to convert <a> tags to router links
      const anchors = this.$el.getElementsByTagName("a"); // Not iterable
      Array.from(anchors).forEach((anchor) => {
        anchor.addEventListener("click", this.handleLinkClick);
      });
    });
  },
};
</script>

<style lang="scss">
.wysiwyg-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    margin-bottom: 1rem;

    &.mb-0 {
      margin-bottom: 0;
    }
  }

  ul {
    padding-left: 1.5rem;
    list-style-type: disc;

    > li {
      margin: 0 1.5em;

      > ul {
        list-style-type: circle;
      }
    }
  }

  hr {
    margin: 1.875rem 0;
    background: rgba(236, 243, 249);
    color: rgba(236, 243, 249);
    display: inline-block;
    width: 100%;
  }

  p + hr {
    margin: 0.875rem 0 1.875rem;
  }

  ol {
    list-style: none;
    counter-reset: item;

    li {
      counter-increment: item;
      margin-bottom: 1rem;
    }

    li:before {
      content: counter(item);
      font-weight: bold;
      width: 1.25rem;
      display: inline-block;
      text-align: left;
    }
  }

  a {
    font-weight: bold;

    &:hover {
      color: #000;
      transition: color;
      transition-duration: 300ms;
    }
  }

  &.text-sm {
    p {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .minus {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: bold;
  }

  figcaption {
    font-size: 0.875rem;
  }
}

.wysiwyg-content a {
  color: #1d70b7;
}

.container-information .wysiwyg-content a {
  color: #009a4e;
}

.container-prevention .wysiwyg-content a {
  color: #e67817;
}

.container-quit .wysiwyg-content a {
  @apply text-green;
}

.max-h-9999px {
  max-height: 9999px !important;
}

.table-container {
  overflow-x: auto;

  @media (min-width: 1366px) {
    overflow-x: hidden;
  }

  > div {
    max-width: fit-content;

    @media (min-width: 1366px) {
      max-width: 100%;
    }

    > img {
      @media (min-width: 1366px) {
        width: 100%;
      }
    }
  }
}
</style>
