<template>
  <section class="md:mx-auto" style="max-width: 800px">
    <div class="aspect-w-16 aspect-h-9">
      <iframe
        :src="src"
        frameborder="0"
        width="640"
        height="360"
        webkitallowfullscreen=""
        mozallowfullscreen=""
        allow="autoplay"
        allowfullscreen=""
        data-ready="true"
      ></iframe>
    </div>
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "SectionVideo",
  props: {
    src: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
